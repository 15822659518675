import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import FurtherReading from "../components/furtherReading"

const PrivacyPolicy = () => (
  <Layout>
    <SEO
      title="Privacy Policy"
      description="Natural Afro Hair Care Privacy Policy"
    />
    <h1>Privacy Policy</h1>
    <p>Effective August 23rd, 2020</p>
    <h2>1. Introduction</h2>

    <p>
      This privacy notice provides you with details of how we collect and
      process your personal data through your use of our websites at
      naturalafrohaircare.com.
    </p>
    <p>
      By providing us with your data, you warrant to us that you are over 13
      years of age.
    </p>
    <p>
      If you have any questions about this privacy policy please contact me via
      the details below.
    </p>
    <p>Email address: info@naturalafrohaircare.com</p>
    <p>
      It is very important that the information we hold about you is accurate
      and up to date. Please let us know if at any time your personal
      information changes by emailing us at info@naturalafrohaircare.com
    </p>
    <p>
      We may change this privacy policy from time to time. If we make changes,
      we will update the “Effective Date” at the top of the page. If we make a
      material change in the way we use your personal information, we will
      prominently note the change through email or notification on our website.
    </p>
    <h2>2. HOW WE COLLECT AND USE INFORMATION</h2>
    <p>We may process the following categories of personal data about you:</p>
    <p>
      <strong>* Communication Data</strong> that includes any communication that
      you send to us whether that be through the contact form on our website,
      through email, text, social media messaging, social media posting or any
      other communication that you send us. We process this data for the
      purposes of communicating with you, for record keeping and for the
      establishment, pursuance or defence of legal claims. Our lawful ground for
      this processing is our legitimate interests which in this case are to
      reply to communications sent to us, to keep records and to establish,
      pursue or defend legal claims.
    </p>
    <p>Some examples include:</p>
    <p>
      <strong>Blogs and Forums</strong>: We may provide an opportunity for you
      to post information to blogs, forums or other public areas on the
      Services. By doing so, you consent to the posting of any Personal
      Information that you provide. You should be aware that such Personal
      Information can be read, collected, or used by other visitors to these
      public areas. We have no control over how these visitors use your Personal
      Information.
    </p>
    <p>
      <strong>Inquiries</strong>: If you send us a comment or question or make
      an inquiry to customer support, we will retain the information that you
      provide to us for the purpose of responding to you, as well as for
      improving the Services and our customers’ use of our services.
    </p>
    <p>
      <strong>* Customer Data</strong> that includes data relating to any
      purchases of goods and/or services such as your name, title, billing
      address, delivery address email address, phone number, contact details,
      purchase details and your card details. We process this data to supply the
      goods and/or services you have purchased and to keep records of such
      transactions. Our lawful ground for this processing is the performance of
      a contract between you and us and/or taking steps at your request to enter
      into such a contract.
    </p>
    <p>Some examples include:</p>
    <p>
      <strong>Registration</strong>: When you register on the Services, such as
      to subscribe to a newsletter, request information or access a Product, you
      will be asked to provide certain Personal Information, which we will use
      to fulfill your request.
    </p>
    <p>
      <strong>Surveys</strong>: We may ask you to complete surveys or opinion
      polls from time to time. We only use the information that we collect from
      surveys in an aggregate manner, not in individually identifiable form. We
      may use specific comments shared on surveys, in an anonymised way. We may
      also reach out for more clarification on survey responses and potentially
      request more and permission to share. But we will never share anything
      personally identifiable shared on a survey unless given consent.
    </p>
    <p>
      <strong>User Data</strong> that includes data about how you use our
      website and any online services together with any data that you post for
      publication on our website or through other online services. We process
      this data to operate our website and ensure relevant content is provided
      to you, to ensure the security of our website, to maintain backups of our
      website and/or databases and to enable publication and administration of
      our website, other online services, and business. Our lawful ground for
      this processing is our legitimate interests which in this case are to
      enable us to properly administer our website and our business.
    </p>
    <p>
      <strong>Technical Data</strong> that includes data about your use of our
      website and online services such as your IP address, your login data,
      details about your browser, length of visit to pages on our website, page
      views and navigation paths, details about the number of times you use our
      website, time zone settings and other technology on the devices you use to
      access our website. The source of this data is from our analytics tracking
      system. We process this data to analyse your use of our website and other
      online services, to administer and protect our business and website, to
      deliver relevant website content and advertisements to you and to
      understand the effectiveness of our advertising. Our lawful ground for
      this processing is our legitimate interests which in this case are to
      enable us to properly administer our website and our business and to grow
      our business and to decide our marketing strategy.
    </p>
    <p>Some examples include:</p>
    <p>
      <strong>Web Logs</strong>: We maintain web logs that automatically record
      basic information about everyone who visits the Services. We use web log
      information to help us design our website, to identify popular features,
      to improve our services, and for other managerial and analytic purposes.
      We may also use web logs to help identify any person who may be misusing
      the Services. These logs contain:
    </p>
    <ul>
      <li>The Internet domain from which you access the Services.</li>
      <li>Your IP address.</li>
      <li>The type of browser and operating system that you use.</li>
      <li>The date and time you visited the Services.</li>
      <li>The pages that you visited on the Services.</li>
      <li>
        The address of the website you linked from to get to the Services.
      </li>
    </ul>
    <p>
      <strong>Marketing Data</strong> that includes data about your preferences
      in receiving marketing from us and our third parties and your
      communication preferences. We process this data to enable you to partake
      in our promotions such as competitions, prize draws and free giveaways, to
      deliver relevant website content and advertisements to you and measure or
      understand the effectiveness of this advertising. Our lawful ground for
      this processing is our legitimate interests which in this case are to
      study how customers use our products/services, to develop them, to grow
      our business and to decide our marketing strategy.
    </p>
    <p>More specifically, here are some examples:</p>
    <p>
      <strong>Linking from Emails</strong>. If you click a link to any of our
      Sites from an email message from us, we will be able to identify your
      email address and associate that email address with the manner in which
      you use the Sites (e.g., which pages you visit or whether you make a
      purchase).
    </p>
    <p>
      <strong>Visitors</strong>: If you are just visiting the Sites and not
      registering, requesting information, contacting us, or posting to a public
      area of the Services, and you did not access the Sites from a link
      contained in one of our email communications, we will not ordinarily
      collect any Personal Information about you except through web logs, as
      described below. In that case, we obtain the information that you provided
      and will use it to respond to your inquiry or fulfill your request if we
      can. We do not share any of this information with anyone else, except to
      respond to your request, if you consent, or as otherwise described in this
      privacy policy.
    </p>
    <p>
      <strong>Web Logs</strong>: We maintain web logs that automatically record
      basic information about everyone who visits the Services. We use web log
      information to help us design our website, to identify popular features,
      to improve our services, and for other managerial and analytic purposes.
      We may also use web logs to help identify any person who may be misusing
      the Services. These logs contain:
    </p>
    <ul>
      <li>The Internet domain from which you access the Services.</li>
      <li>Your IP address.</li>
      <li>The type of browser and operating system that you use.</li>
      <li>The date and time you visited the Services.</li>
      <li>The pages that you visited on the Services.</li>
      <li>
        The address of the website you linked from to get to the Services.
      </li>
    </ul>
    <p>
      <strong>Cookies</strong>: We use cookies, web beacons and similar
      technology to help you navigate the Services, to allow us to learn how you
      use the Services, and to improve the Services. Cookies are text files we
      place in your computer’s browser to store your preferences. Cookies, by
      themselves, do not reveal any Personal Information unless you choose to
      provide this information to us by, for example, registering at our Site.
      However, once you choose to provide Personal Information to the Services,
      this information may be linked to the data stored in the cookie. If you
      decide not to permit cookies on your computer, this may affect your
      ability to use the Services.
    </p>
    <p>
      <strong>
        * We may use Customer Data, User Data, Technical Data and Marketing Data
      </strong>
       to deliver relevant website content and advertisements to you (including
      Facebook adverts or other display advertisements) and to measure or
      understand the effectiveness of the advertising we serve you. Our lawful
      ground for this processing is legitimate interests which is to grow our
      business. We may also use such data to send other marketing communications
      to you. Our lawful ground for this processing is either consent or
      legitimate interests (namely to grow our business).
    </p>
    <h2>Sensitive Data</h2>
    <p>
      We do not collect any Sensitive Data about you. Sensitive data refers to
      data that includes details about your race or ethnicity, religious or
      philosophical beliefs, sex life, sexual orientation, political opinions,
      trade union membership, information about your health and genetic and
      biometric data. We do not collect any information about criminal
      convictions and offences.
    </p>
    <p>
      Where we are required to collect personal data by law, or under the terms
      of the contract between us and you do not provide us with that data when
      requested, we may not be able to perform the contract (for example, to
      deliver goods or services to you). If you don’t provide us with the
      requested data, we may have to cancel a product or service you have
      ordered but if we do, we will notify you at the time.
    </p>
    <p>
      We will only use your personal data for a purpose it was collected for or
      a reasonably compatible purpose if necessary. For more information on this
      please email us at info@naturalafrohaircare.com and please use the subject
      line: “Data Request.” In case we need to use your details for an unrelated
      new purpose we will let you know and explain the legal grounds for
      processing.
    </p>
    <p>
      We may process your personal data without your knowledge or consent where
      this is required or permitted by law.
    </p>
    <p>
      We do not carry out automated decision making or any type of automated
      profiling.
    </p>
    <h2>HOW WE COLLECT YOUR PERSONAL DATA</h2>
    <p>
      We may collect data about you by you providing the data directly to us
      (for example by filling in forms on our site or by sending us emails). We
      may automatically collect certain data from you as you use our website by
      using cookies and similar technologies.
    </p>
    <p>
      We may receive data from third parties such as analytics providers such as
      Google based outside the EU, advertising networks such as Facebook based
      outside the EU, such as search information providers such as Google based
      outside the EU, providers of technical, payment and delivery services,
      such as data brokers or aggregators.
    </p>
    <p>
      We may also receive data from publicly available sources such as Companies
      House and the Electoral Register based inside the EU.
    </p>
    <h2>MARKETING COMMUNICATIONS</h2>
    <p>
      Our lawful ground of processing your personal data to send you marketing
      communications is either your consent or our legitimate interests (namely
      to grow our business).
    </p>
    <p>
      Before we share your personal data with any third party for their own
      marketing purposes we will get your express consent.
    </p>
    <p>
      You can ask us or third parties to stop sending you marketing messages at
      any time by clicking the 1-click unsubscribe button at the bottom of our
      emails or by sending us an email at info@naturalafrohaircare.com
    </p>
    <p>
      If you opt out of receiving marketing communications this opt-out does not
      apply to personal data provided as a result of other transactions, such as
      purchases, warranty registrations etc.
    </p>
    <h2>How We Use Your Information</h2>
    <p>Here is how we may use your information and data:</p>
    <p>
      <strong>Communicating with You</strong>. We may use information that we
      collect from you for the purpose of communicating with you and responding
      to your inquiries. Those communications could, for example, take the form
      of service updates, promotions for products or services that we or one of
      our marketing partners provide, or e-newsletters.
    </p>
    <p>
      <strong>Service Providers</strong>: We at times contract with third party
      service providers to perform functions for us. These service providers may
      be given access to the information we collect for the purpose of
      performing those functions, but they will be contractually bound to
      maintain the confidentiality of the information and not to use it for any
      other purpose.
    </p>
    <p>
      <strong>Social Media</strong>. The Sites may include social media
      features, such as Facebook “Like” button and a “Share” button. These
      features may collect your IP address and which page you are visiting on
      the Site, and they may set a cookie to enable the feature to function
      properly. Your interactions with these features are governed by the
      privacy policy of the company that provides the feature. Business
      Transfers: If we were to transfer all or substantially all of our assets
      to another company, one of the assets we would likely include in the
      transfer is our database of Personal Information.
    </p>
    <p>
      <strong>Disclosures for Legal Reasons</strong>: We will disclose Personal
      Information when we are required to do so by law, regulation or securities
      exchange requirement, for example, in response to a court order or
      subpoena. We also may disclose such information in response to a law
      enforcement agency’s request or when we believe such disclosure is
      appropriate to protect our legal rights or to safeguard anyone’s property
      or safety.
    </p>
    <h2>How We Share Information – And Why</h2>
    <p>
      We may have to share your personal data with the parties set out below:
    </p>
    <p>
      * Professional advisers including lawyers, bankers, auditors and insurers.
    </p>
    <p>* Government bodies that require us to report processing activities.</p>
    <p>
      * With third-party advertising platforms so that we can create marketing
      campaign advertising (e.g. using your email address to create a Facebook
      targeting audience).
    </p>
    <p>
      * When you participate in an interactive area of our website, service, and
      products, and your information may be displayed to other users: such
      information may include, your name, photo, the comment, and other
      information you chose to provide at the time of submission. In the case of
      Surveys, we will never share personally identifiable information without
      express consent.
    </p>
    <p>
      * In response to a request for information if we believe disclosure is in
      accordance with or required by, any applicable law, regulation or legal
      process;
    </p>
    <p>
      * If we believe your actions are inconsistent with our user agreements or
      policies, or to protect the rights, property, and safety of Company or
      others;
    </p>
    <p>
      * Third parties to whom we sell, transfer, or merge parts of our business
      or our assets.
    </p>
    <p>* With your consent or at your direction.</p>
    <p>
      We require all third parties to whom we transfer your data to respect the
      security of your personal data and to treat it in accordance with the law.
      We only allow such third parties to process your personal data for
      specified purposes and in accordance with our instructions.
    </p>
    <h2>INTERNATIONAL TRANSFERS</h2>
    <p>
      We are subject to the provisions of the General Data Protection
      Regulations that protect your personal data. Where we transfer your data
      to third parties outside of the EEA, we will ensure that certain
      safeguards are in place to ensure a similar degree of security for your
      personal data. As such:
    </p>
    <p>
      * We may transfer your personal data to countries that the European
      Commission have approved as providing an adequate level of protection for
      personal data by; or
    </p>
    <p>
      * If we use US-based providers that are part of EU-US Privacy Shield, we
      may transfer data to them, as they have equivalent safeguards in place; or
    </p>
    <p>
      * Where we use certain service providers who are established outside of
      the EEA, we may use specific contracts or codes of conduct or
      certification mechanisms approved by the European Commission which give
      personal data the same protection it has in Europe.
    </p>
    <p>
      If none of the above safeguards is available, we may request your explicit
      consent to the specific transfer. You will have the right to withdraw this
      consent at any time.
    </p>
    <h2>DATA SECURITY</h2>
    <p>
      We have put in place security measures to prevent your personal data from
      being accidentally lost, used, altered, disclosed, or accessed without
      authorisation. We also allow access to your personal data only to those
      employees and partners who have a business need to know such data. They
      will only process your personal data on our instructions and they must
      keep it confidential.
    </p>
    <p>
      We have procedures in place to deal with any suspected personal data
      breach and will notify you and any applicable regulator of a breach if we
      are legally required to.
    </p>
    <h2>DATA RETENTION</h2>
    <p>
      We will only retain your personal data for as long as necessary to fulfill
      the purposes we collected it for, including for the purposes of satisfying
      any legal, accounting, or reporting requirements.
    </p>
    <p>
      When deciding what the correct time is to keep the data for we look at its
      amount, nature and sensitivity, the potential risk of harm from
      unauthorised use or disclosure, the processing purposes, if these can be
      achieved by other means and legal requirements.
    </p>
    <p>
      For tax purposes, the law requires us to keep basic information about our
      customers (including Contact, Identity, Financial and Transaction Data)
      for six years after they stop being customers.
    </p>
    <p>
      In some circumstances, we may anonymise your personal data for research or
      statistical purposes in which case we may use this information
      indefinitely without further notice to you.
    </p>
    <h2>YOUR LEGAL RIGHTS</h2>
    <p>
      Under data protection laws you have rights in relation to your personal
      data that include the right to request access, correction, erasure,
      restriction, transfer, to object to processing, to portability of data and
      (where the lawful ground of processing is consent) to withdraw consent.
    </p>
    <p>
      You can see more about these rights at:
      https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/
      If you wish to exercise any of the rights set out above, please email us
      at info@naturalafrohaircare.com
    </p>
    <p>
      You will not have to pay a fee to access your personal data (or to
      exercise any of the other rights). However, we may charge a reasonable fee
      if your request is clearly unfounded, repetitive or excessive or refuse to
      comply with your request in these circumstances.
    </p>
    <p>
      We may need to request specific information from you to help us confirm
      your identity and ensure your right to access your personal data (or to
      exercise any of your other rights). This is a security measure to ensure
      that personal data is not disclosed to any person who has no right to
      receive it. We may also contact you to ask you for further information in
      relation to your request to speed up our response.
    </p>
    <p>
      We try to respond to all legitimate requests within one month.
      Occasionally it may take us longer than a month if your request is
      particularly complex or you have made a number of requests. In this case,
      we will notify you.
    </p>
    <p>
      If you are not happy with any aspect of how we collect and use your data,
      you have the right to complain to the Information Commissioner’s Office
      (ICO), the UK supervisory authority for data protection issues
      (www.ico.org.uk). We should be grateful if you would contact us first if
      you do have a complaint so that we can try to resolve it for you.
    </p>
    <h2>THIRD-PARTY LINKS</h2>
    <p>
      This website may include links to third-party websites, plug-ins and
      applications. Clicking on those links or enabling those connections may
      allow third parties to collect or share data about you. We do not control
      these third-party websites and are not responsible for their privacy
      statements. When you leave our website, we encourage you to read the
      privacy notice of every website you visit.
    </p>
    <h2>COOKIES</h2>
    <p>
      You can set your browser to refuse all or some browser cookies, or to
      alert you when websites set or access cookies. If you disable or refuse
      cookies, please note that some parts of this website may become
      inaccessible or not function properly.
    </p>
    <h2>COLLECTION OF INFORMATION BY THIRD PARTIES</h2>
    <p>
      We may use a third-party advertising company to serve ads when you visit
      our Site. If we do so, this company may use information (not including
      your name, address, email address or telephone number) about your visit to
      the Services in order to provide advertisements about goods and services
      that may be of interest to you. In the course of serving advertisements to
      the Services, the advertiser might place or recognise a unique “cookie” on
      your browser.
    </p>
    <p>
      We may offer you the opportunity to contact other companies, such as
      advertisers, marketing affiliates and content providers, to obtain
      information or to buy products or services. You decide when and whether to
      give your Personal Information to these companies. If you provide
      information, its use and security is governed by the policies and
      practices of those companies and not by this privacy policy.
    </p>
    <p>
      We make no representations or warranties about any of these other
      companies or their privacy or security policies. Once you click through to
      an unaffiliated website, this privacy policy no longer applies. We
      strongly urge you to read the applicable policies of these other companies
      and their websites.
    </p>
    <h3>YOUR PRIVACY CHOICES</h3>
    <p>
      If you would like us to stop sending you email or other communications or
      would like to access your Personal Information to correct or delete it,
      you may send us a request at our email or postal addresses indicated
      below. We will endeavor to satisfy such requests within a reasonable time.
    </p>
    <p>info@naturalafrohaircare.com</p>
    <h3>DO NOT TRACK</h3>
    <p>
      There will be no difference in how we collect or use data if you set your
      web browser to send a “Do not track” or similar request when you visit
      this Site.
    </p>
    <h3>LINKS</h3>
    <p>
      Our Site includes links to other websites whose privacy practices differ
      from ours. If you submit personal information to any of those websites,
      your information will be subject to their practices and policies.
    </p>
    <h3>SECURITY</h3>
    <p>
      We maintain all Personal Information with physical, electronic, and
      managerial safeguards to help ensure the security and confidentiality of
      the data against reasonably foreseeable risks. Security is designed to
      prevent unauthorised use, access, disclosure, destruction, and changes of
      data.
    </p>
    <h3>CHILDREN</h3>
    <p>
      This Site is intended for use by adults. We do not target, or knowingly
      collect any Personal Information from, children under the age of 13.
    </p>
    <h3>ACCOUNTABILITY</h3>
    <p>
      We are committed to adhering to our privacy policy and applicable laws and
      to making your online experience secure and valuable. If you have a
      question, request, complaint, dispute or concern related to this privacy
      policy, you may send a message by email to info@naturalafrohaircare.com .
      I will work with you to obtain a prompt resolution of any issues you may
      have.
    </p>

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default PrivacyPolicy
